<template>
  <v-dialog v-model="dialog" persistent width="350">
    <v-card>
      <v-card-title>
        Избери партньор
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="form">
          <v-select :items="partners"
                    item-text="name"
                    item-value="id"
                    return-object
                    v-model="partner"
                    label="Партньор"
                    :rules=" [v => !!v || 'Не е избран партньор. Моля изберете партньор преди да продължите']"
          >
            <template v-slot:item="{item}">
              {{item.partner? item.partner.Company:'Няма свързан партньор'}} -   {{item.name}}
            </template>

          </v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="cancel()">
          Отказ
        </v-btn>
        <v-btn text color="primary" @click="confirm()">
          Продължи
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "SelectPartner",
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    partners: [],
    partner: null,
    partnerSelectionRules:[

    ],
    valid:true
  }),
  methods: {
    fetchPartners() {
      let self = this
      this.get('partners/registered/get').then(r => {
        self.partners = r.partners
      })
    },
    open() {
      this.dialog = true
      this.fetchPartners()
      return new Promise(((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      }))
    },
    confirm() {
      let isValid = this.$refs.form.validate()
      if(isValid){
        this.dialog = false
        this.resolve(true)
      }

    },
    cancel() {
      this.dialog = false
      this.resolve(false)
      this.$router.back();
    },
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user
      },
      set(val) {
        this.$store.commit('setUser', val)
      }
    }
  },
  watch: {
    partner(val) {
      this.user.group_id = val.group_id
      this.user.partner_id = val.partner_id
      this.user.is_empty = true;
    }
  }

}
</script>

<style scoped>

</style>